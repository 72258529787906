const monitorMap = {
    state: {
        resourcesSelectType: ''
    },
    mutations: {
        SET_MONITOR_RESOURCES_SELECTTYPE: (selectType) => {
            state.resourcesSelectType = selectType
        },
    },
    actions:{
        set_monitor_resources_selecttype ({ commit }, selectType) {
            commit('SET_MONITOR_RESOURCES_SELECTTYPE', selectType)
        }
    },
}
export default monitorMap