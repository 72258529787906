import Vue from 'vue'
import VueStorage from 'vue-ls'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import animate from 'animate.css'
import moment from 'moment'
import bootstrap from './core/bootstrap'
import notificationUtil from './utils/notificationUtil'
import JsEncrypt from 'jsencrypt'
import PortalVue from 'portal-vue'

import * as filters from './filters' // global filters

// 图片放大
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.prototype.$jsEncrypt = new JsEncrypt()
// 数据刷新间隔ms
Vue.prototype.$refreshInterval = 1200000 // 20分钟
Vue.prototype.$moment = moment
//  全局组件之间通信
Vue.prototype.$eventBus = new Vue()
Vue.prototype.notificationUtil = notificationUtil

Vue.config.productionTip = false
let options = {
  namespace: 'pro__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};
Vue.use(Antd);
Vue.use(VueStorage, options);
Vue.use(animate)
Vue.use(Viewer)
Vue.use(PortalVue)

// Viewer.setDefaults({
//   Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
// })

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  created () {
      bootstrap()
  },
  render: h => h(App)
}).$mount('#app')
