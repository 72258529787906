import Vue from 'vue'
import axios from 'axios'
//import notificationUtil from './notificationUtil'
import store from '@/store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Modal from 'ant-design-vue/lib/modal'
import { getCheckCode } from '@/utils/util.js'

const service = axios.create({
  // // TODO wxy 生产环境放出baseURL
  baseURL: process.env.VUE_APP_HTTP_URL // api base_url
  // timeout: 8000 // 请求超时时间/
})

// 高德事件http
const eventService = axios.create({
  baseURL: process.env.VUE_APP_EVENT_HTTP_URL // 事件 base_url
  // timeout: 8000 // 请求超时时间
})

let tokenExpire = true

const err = (error) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
    case 400:
      message = '参数错误'
      break
    case 401: // 登录失败
      message = error.response.data
      break
    case 403: // token过期
      message = '登录过期，请重新登录'
      break
    case 402:
      message = error.response.data
      break
    case 404:
      message = '找不到服务'
      break
    case 412:// 业务异常
      message = error.response.data
      break
    case 504:
      message = '网络连接失败'
      break
    case 500:
      message = error.response.data
      break
    default:
      message = error.response.data
      break
    }
  } else {
    message = '请求超时，请重试'
  }

  if (error.response.status === 403) {
    if (tokenExpire) {
      tokenExpire = false
      Modal.error({
        title: '错误提示',
        keyboard: false,
        content: message,
        okText: '确定',
        onOk: reload
      })
    }
  } else {
    // notificationUtil.error({
    //   description: message
    // })
  }

  function reload () {
    store.dispatch('Logout').then(() => {
      location.reload() // 为了重新实例化vue-router对象 避免bug
    })
  }

  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers[ACCESS_TOKEN] = token // 让每个请求携带自定义 token 请根据实际情况自行修改

    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    // if (config.method === 'get') {
    //   config.paramsSerializer = function(params) {
    //     return qs.stringify(params, { arrayFormat: 'repeat' })
    //   }
    // }
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  // 更新token
  if (response.headers.authorization) {
    Vue.ls.set(ACCESS_TOKEN, response.headers.authorization)
  }
  return response
}, err)

export function get (url, params, checkCode = false) {
  if (checkCode) {
    let checkcode = getCheckCode()
    params = { ...params, checkcode }
  }
  return service({
    url: url,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function post (url, params) {
  return service({
    url: url,
    method: 'post',
    data: params
  })
}

export function put (url, params) {
  return service({
    url: url,
    method: 'put',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function del (url, params) {
  return service({
    url: url,
    method: 'delete',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function download (url, params) {
  return service({
    url: url,
    method: 'post',
    data: params,
    responseType: 'arraybuffer'
  })
}

export function eventGet (url, params) {
  return eventService({
    url: url,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
