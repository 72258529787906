import Vue from 'vue'
import { login } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
// import axios from 'axios'

const user = {
  state: {
    token: '',
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    SetUser ({ commit }, user) {
      commit('SET_INFO', user)
    },
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then((response) => {
          let user = response.data
          let token = response.headers['authorization']
          Vue.ls.set(ACCESS_TOKEN, token)
          commit('SET_TOKEN', token)
          let userInfo = {
            id: user.id,
            name: user.name !== null ? user.name : user.account,
            account: user.account,
            roleIds: user.roleIds,
            isHighStream: user.isHighStream
          }
          Vue.ls.set('user', userInfo)
          commit('SET_INFO', userInfo)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit }) {
      commit('SET_TOKEN', '')
      Vue.ls.remove(ACCESS_TOKEN)
      Vue.ls.remove('user')
      return new Promise((resolve) => {
        /*if (Vue.ls.get('user')) {
          let account = Vue.ls.get('user').account
          logout(account).then(() => {
            resolve()
          }).catch(() => {
            resolve()
          })
        } else {
          resolve()
        }*/
        resolve()
      })
    },

    GetUserInfo(){
     let userInfo = Vue.ls.get('user');
     return userInfo;
    }

  }
}

export default user
