import { get } from '../utils/request'
const URL = '/manage/'

// 根据角色查询资源
export const listResource = roleId => get(`${URL}/resource/role/${roleId}`)



// 查询高速公路-路线下的资源在线率
export const listHighwayRouteCameraPercentage = p => get(`${URL}/road/camera/percentage`, p)
// 查询高速公路-区域下的资源在线率
export const listHighwayAreaCameraPercentage = p => get(`${URL}/area/camera/percentage`, p)
// 查询高速公路-收费所下的资源在线率
export const listHighwayDepartmentCameraPercentage = p => get(`${URL}/department/camera/percentage`, p)
// 查询客运-区域下的资源在线率
export const listPassengerAreaCameraPercentage = p => get(`${URL}/area/passenger/camera/percentage`, p)

// 临时接口-查询高速公路下新接路段资源及在线率
export const listHighwayNewRoadCameraPercentageTemp = p => get(`${URL}/department/camera/percentage/temp`, p)

