import Vue from 'vue'
import Router from 'vue-router'
import { decryptDes } from '../utils/desUtil'

Vue.use(Router)

const routes =  [
  {
    path: '/',
    redirect: 'login',
    component: resolve => require(['../App.vue'], resolve),
    children:[
      {
        path: 'login',
        name: 'login',
        component: resolve => require(['../views/Login.vue'], resolve)
      },
      {
        path: '/monitorIndex',
        component: resolve => require(['../views/monitor/MonitorIndex.vue'], resolve),
        meta: { requiresAuth: true },
        children: [
          {
            path: '/',
            component: resolve => require(['../views/monitor/MonitorMain.vue'], resolve),
            meta: { requiresAuth: true }
          },
          {
            path: '/analyse',
            component: resolve => require(['../views/analyse/AnalyseMain.vue'], resolve),
            meta: { requiresAuth: true }
          },
          {
            path: '/importantRoad',
            component: resolve => require(['../views/importantRoad/ImportantRoad.vue'], resolve),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
          path: '/security',
          name: 'security',
          component: resolve => require(['../views/security/Security.vue'], resolve)
      },
    ]
  },
]

const router = new Router({
  /*  mode: 'history',*/
  routes
});
/**/
router.beforeEach((to, from, next) => {
  // 允许其他系统通过a标签直接跳转进入本系统
  if (to.path === '/thirdparty/show') {
    let d1 = new Date(expireDate); // config.js中的全局变量
    let d2 = new Date();
    if (d1.getTime() > d2.getTime()) { // 授权未过期
      let param = {
        account: to.query.a,
        password: decryptDes(to.query.b, 'Rhea2021')
      }
      router.app.$options.store.dispatch('Login', param).then(() => {
        next({
          path: '/monitorIndex'
        })
      })
    } else {
      alert('授权已过期!')
    }
  }
  let userInfo = Vue.ls.get('user');
  next();
  if (to.matched.some(r => r.meta.requiresAuth)) {
    if (userInfo != null) {
      if (Vue.ls.get('stage')===1) {
          next({
              path: '/security',
          })
      }else {
          next();
      }
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  }
  else {
    next();
  }
})

export  default  router;
