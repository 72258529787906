import notification from 'ant-design-vue/es/notification'

export default class notificationUtil {
  /**
   * 信息提示
   * @param title 提示标题，非必填，默认（信息提示）
   * @param description 提示信息，必填
   */
  static info ({ title = '信息提示', description }) {
    notification['info']({
      message: title,
      description: description
    })
  }

  /**
   * 成功提示
   * @param title 提示标题，非必填，默认（成功提示）
   * @param description 提示信息，必填
   */
  static success ({ title = '成功提示', description }) {
    notification['success']({
      message: title,
      description: description
    })
  }

  /**
   * 警告提示
   * @param title 提示标题，非必填，默认（警告提示）
   * @param description 提示信息，必填
   */
  static warning ({ title = '警告提示', description }) {
    notification['warning']({
      message: title,
      description: description
    })
  }

  /**
   * 错误提示
   * @param title 提示标题，非必填，默认（错误提示）
   * @param description 提示信息，必填
   * @param duration 自动关闭时间，非必填，默认（4.5）
   * @param fn 关闭事件，非必填，默认（无）
   */
  static error ({ title = '错误提示', description, duration = 4.5, fn }) {
    notification['error']({
      message: title,
      description: description,
      duration: duration,
      onClose: fn
    })
  }
}
