// import { listAllEventType } from '../../api/eventType'
import { listResource } from '../../api/resource'
import Vue from 'vue'

const event = {
    state: {
        alertType: {}
    },
    mutations: {
        SET_ALERT_TYPE: (state, data) => {
            let result = {}
            data.forEach(item => {
                result[item.leve3] = item.resourceName
            })
            state.alertType = result
        }
    },
    actions: {
        SetAlertType ({ commit }) {
            const roleId = Vue.ls.get('user').roleIds[0] // 角色id
            listResource(roleId).then((res) => {
                let data = res.data.filter(item => item.leve1 === 'event' && item.leve3 !== null)
                commit('SET_ALERT_TYPE', data)
            })
        }
    }
}

export default event
