import JsEncrypt from 'jsencrypt'
import { encryptDes } from './desUtil'
import moment from 'moment'

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone (source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'shallowClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}


export function calcDateDifference (start, end) {
    // let new_date = new Date()
    // let old_date = new Date(this.$moment(this.deviceView.collectTime).format('YYYY-MM-DD HH:mm:ss'))
    let date = new Date(end) - new Date(start) //计算时间差

    //计算出相差天数
    let days = Math.floor(date/(24*3600*1000))
    //计算出小时数
    let leave1=date%(24*3600*1000)    //计算天数后剩余的毫秒数
    let hours=Math.floor(leave1/(3600*1000))
    //计算相差分钟数
    let leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
    let minutes=Math.floor(leave2/(60*1000))
    //计算相差秒数
    // let leave3=leave2%(60*1000)      //计算分钟数后剩余的毫秒数
    // let seconds=Math.round(leave3/1000)
    return `${days > 0 ? days + '天' : ''}${hours > 0 ? hours + '小时' : ''}${minutes > 0 ? minutes : '1'}分钟`
}


/*
时间戳转换成几分钟前，几小时前，几天前
 */
export function formatMsgTime (timespan) {
    var dateTime = new Date(timespan) // 将传进来的字符串或者毫秒转为标准时间
    // var year = dateTime.getFullYear()
    // var month = dateTime.getMonth() + 1
    // var day = dateTime.getDate()
    // var hour = dateTime.getHours()
    // var minute = dateTime.getMinutes()
    // var second = dateTime.getSeconds()
    var millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
    var now = new Date() // 获取本机当前的时间
    var nowNew = now.getTime() // 将本机的时间转换为毫秒
    var milliseconds = 0
    var timeSpanStr
    milliseconds = nowNew - millisecond
    if (milliseconds <= 1000 * 60 * 1) { // 小于一分钟展示为刚刚
        timeSpanStr = '刚刚'
    } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) { // 大于一分钟小于一小时展示为分钟
        timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前'
    } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) { // 大于一小时小于一天展示为小时
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
    }
    else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds) { // 大于一天展示为天
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
    }
    // else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
    //     timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute
    // }
    // else {
    //     timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
    // }
    return timeSpanStr
}

export function getRSApass (password) {
    let jse = new JsEncrypt()
    // 公钥
    var publicString = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCl5/bpkuOKaDPtXEP/ICZytD4ywbWXbWHoHZV2mQSzs9bDbox5x7Q1Fn6u8oY+rVd0LQbor3P2SxHoPrvQZw7HL0ShbBP03fxLl9zaUBNFg7btFxzH4NHQ17I1nQW1PxNwypMFocTINJJrAcfPrDl/rEMzgMcZNUHitHX/jsv4DwIDAQAB'
    jse.setPublicKey(publicString)
    // 加密内容
    let encrypted = jse.encrypt(password)
    return encrypted
}

export function getCheckCode () {
    return encryptDes('rhea-' + moment(new Date()).format('YYYYMMDDHHmmss'), 'gsjt2021')
}
